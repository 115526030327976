body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

html {
    background-color: #002416;
}

.image {
    max-width: '90%';
    border: 'solid 5px';
}

.loading {
    filter: blur(10px);
    clip-path: inset(0);
}

.loaded {
    max-width: '90%';
    border: 'solid 5px';
}
